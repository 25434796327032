<div class="container">
  <div class="rowspacer"></div>
  <mat-card>
    <mat-card-title>
      404: Page Not Found
    </mat-card-title>

    <mat-card-content>
      <mat-card-subtitle>
        Oops! We kunnen niet vinden wat je zoekt!
      </mat-card-subtitle>
      <p>
        Klik <a href="../">hier</a> om terug te keren naar de home pagina en probeer het nog een keer.
      </p>
    </mat-card-content>
  </mat-card>
</div>
