import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Loader } from '@googlemaps/js-api-loader';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {


  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'Dokter67 - Contact'},
      {name: 'author', content: 'Arthur Callant'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'date', content: '2021-09-29', scheme:'YYYY-MM-DD'},
      {charset: 'UTF-8'},
      {name: 'keywords', content: 'Dokter67, Sportarts, Eke, Nazareth, Dokter Kris Vanderlinden, Dokter Maxime Callant, Huisarts, Kris Vanderlinden, Maxime Callant, contact, contactinformatie, openingstijden, openingsuren, adres, stationsstraat 67, stationsstraat, huisbezoeken, bereikbaarheid, auto, bus, trein, openbaar vervoer'}
    ]);
    this.setTitle('Contact - Dokter67');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {
    let loader = new Loader({
      apiKey: 'AIzaSyCKa2XQqdXWMSocO3XOGioqWZMTUChTSoE'
    })

    loader.load().then(() => {
      const contentString =
      '<div id="content">' +
      '<div id="siteNotice">' +
      '</div>' +
      '<h1 id="firstHeading" class="firstHeading">Dokter67</h1>' +
      '<div id="bodyContent">' +
      '<p>Dokter Kris Vanderlinden</p><p>Stationsstraat 67</p><p>9810 Eke-Nazareth</p></div></div>' + 
      '<a href="https://www.google.be/maps/dir//Dokter67,+Stationsstraat+67,+9810+Nazareth/@50.959015,3.6309683,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47c373d1328d416b:0x7f3c0dcf089b7504!2m2!1d3.6354532!2d50.9590153">Routebeschrijving</a>';

      const infoWindow = new google.maps.InfoWindow({
        content: contentString,
      });

        const map = new google.maps.Map(document.getElementById('map'),{
        center: { lat: 50.95909554013904, lng: 3.6353654426612474 },
        zoom: 16
      });
      const marker = new google.maps.Marker({
        position: { lat: 50.95909554013904, lng: 3.6353654426612474 },
        map: map,
        title: 'Dokter Kris Vanderlinden',
      });
      marker.addListener("click", () => {
        infoWindow.open({
          anchor: marker,
          map,
          shouldFocus: false,
        });
      });
    })
  }

}
