import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit {
  @Input() open: boolean = false;
  @Output() action: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() {}

  onCloseClick(e: Event) {
    this.action.emit(e);
  }

  ngOnInit(): void {}
}
