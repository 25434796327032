<div
  [ngClass]="{
    'modal-wrapper': true,
    'is-open': open
  }"
>
  <div class="modal">
    <mat-card>
      <ng-content></ng-content>
      <button mat-raised-button class="mt-auto" (click)="onCloseClick($event)">
        Oké, ik heb het begrepen!
      </button>
    </mat-card>
  </div>
</div>
