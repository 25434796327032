import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { MaterialModule } from './material/material.module';
import { HomeComponent } from './pages/home/home.component';
import { CovidComponent } from './components/covid/covid.component';
import { CovidBannerComponent } from './components/covid-banner/covid-banner.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { AppRoutingModule } from './app-routing.module';
import { ParallaxDirective } from './parallax.directive';
import { FooterComponent } from './layout/footer/footer.component';
import { ContactComponent } from './components/contact/contact.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SportgeneeskundeComponent } from './pages/sportgeneeskunde/sportgeneeskunde.component';
import { LinksPageComponent } from './pages/links-page/links-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PraktijkinfoComponent } from './pages/praktijkinfo/praktijkinfo.component';
import { NewsComponent } from './components/news/news.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { ModalComponent } from './components/modal/modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    CovidComponent,
    CovidBannerComponent,
    NotFoundComponent,
    MainNavComponent,
    ParallaxDirective,
    FooterComponent,
    ContactComponent,
    SportgeneeskundeComponent,
    LinksPageComponent,
    PraktijkinfoComponent,
    NewsComponent,
    ModalComponent
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    AppRoutingModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    NgbModule,
    AngularStickyThingsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
