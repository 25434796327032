<div class="container">
  <div class="row">
    <mat-card>
      <mat-card-title class="page-title"> Sportgeneeskunde </mat-card-title>
      <mat-card-content>
        <p>
          Dokter Kris Vanderlinden en dokter Maxime Callant zijn gespecialiseerd
          in sportgeneeskunde. U kan in de praktijk terecht voor
          sportgerelateerde letsels, een inspanningsproef en sportmedische
          keuring.
        </p>
        <p>
          Dokter Vanderlinden is tevens verbonden aan de KBVB als bondsarts bij
          de vrouwenvoetbalploeg “The Red Flames”.
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-title> Inspanningsproef met lactaatmeting </mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col-lg-6">
            <p>
              Met een fietsonderzoek kan op een accurate manier het
              conditieniveau bepaald worden. Op de fiets worden toenemende
              wattages getrapt terwijl het hartritme en de bloeddruk worden
              gemonitord. Dankzij de lactaatmetingen worden specifieke
              trainingszones afgebakend.
            </p>
            <p>Praktisch:</p>
            <ul>
              <li>
                U reserveert online een afspraak via
                <a
                  href="https://www.mtc-it4.be/patient/index.html#/appointments/day/bjNkcWkyQmFRMkpDVTRFNndleFgzRG8wSTNNVEVGMkxMdFZpeGQ2OStyYkhpeHdsYzJSRTBmazFFTzRZTzJMTw=="
                  >afspraken.be</a
                >
                voor de voorbereiding van de inspanningsproef. Er wordt een
                anamnese afgenomen en u wordt klinisch onderzocht. Indien een
                tegenaanwijzing voor een inspanningsproef wordt gevonden, wordt
                u verwezen voor specialistisch nazicht.
              </li>
              <li>
                Een afspraak wordt gemaakt voor de inspanningsproef. Vergeet
                zeker geen sportkleren mee te nemen!
              </li>
              <li>
                Tijdens de fietsproef wordt regelmatig het lactaat gemeten en
                zal gevraagd worden hoe lastig de proef wordt ervaren, en of er
                klachten optreden
              </li>
              <li>
                U krijgt de gelegenheid zich te douchen na de proef indien
                gewenst.
              </li>
              <li>
                Er wordt een nieuwe afspraak vastgelegd om de geïnterpreteerde
                resultaten te bespreken.
              </li>
            </ul>
          </div>
          <div class="col-lg-3">
            <img
              src="../../assets/images/14.jpg"
              class="sport-img"
              alt="Inspanningsproef met lactaatmeting"
            />
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-title> Echografie </mat-card-title>
      <mat-card-content>
        <div>
          <div class="col-lg-6">
            <p>
              Via echografie kunnen op een niet-invasieve manier de spieren en
              pezen in beeld gebracht worden. Indien echografie gewenst is, maak
              hiervoor een afspraak online bij dokter Vanderlinden via
              <a
                href="https://www.mtc-it4.be/patient/index.html#/appointments/day/bjNkcWkyQmFRMkpDVTRFNndleFgzRG8wSTNNVEVGMkxMdFZpeGQ2OStyYkhpeHdsYzJSRTBmazFFTzRZTzJMTw=="
                >afspraken.be</a
              >
              en vermeldt dit in de reden van afspraak.
            </p>
          </div>
          <div>
            <img
              src="../../assets/images/11.jpg"
              class="img-small"
              loading="lazy"
              alt="Echografie Dokter67"
            />
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-title> Sportmedische keuringen </mat-card-title>
      <mat-card-content>
        <p>
          Voor de meeste sporten is een toelatingsattest van een arts nodig. U
          kan hier terecht voor een sportonderzoek en medische keuring.
        </p>
        <p>Praktisch:</p>
        <ul>
          <li>
            Vul de vragenlijst in op
            <a href="https://www.sportkeuring.be/">www.sportkeuring.be</a>
            en stuur deze door naar dokter Kris Vanderlinden.
          </li>
          <li>
            Maak een afspraak online via
            <a
              href="https://www.mtc-it4.be/patient/index.html#/appointments/day/bjNkcWkyQmFRMkpDVTRFNndleFgzRG8wSTNNVEVGMkxMdFZpeGQ2OStyYkhpeHdsYzJSRTBmazFFTzRZTzJMTw=="
              >afspraken.be</a
            >
            en vermeldt dat het om een sportmedische keuring gaat.
          </li>
          <li>
            Vergeet niet de nodige administratieve documenten mee te nemen naar
            de praktijk. Deze worden meestal door de specifieke federatie naar
            de sporter gestuurd.
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
</div>
