<div class="container">
  <div class="row">
    <mat-card>
      <mat-card-title class="page-title">
        Interessante links
      </mat-card-title>
      <mat-card-content>
        <mat-card-subtitle>
          Huisartsgeneeskunde
        </mat-card-subtitle>
        <ul>
          <li>Betrouwbare info over gezondheid en ziekten: <a href="https://www.gezondheidenwetenschap.be/">www.gezondheidenwetenschap.be</a></li>
          <li>Betrouwbare info over gezondheid en ziekten: <a href="https://www.thuisarts.nl/">thuisarts.nl</a></li>
          <li>Advies Reisgeneeskunde: <a href="https://www.wanda.be/nl/">www.wanda.be</a></li>
          <li>Huisartsen Schelde Leie: <a href="https://www.huisartsenkring-hsl.be/">www.huisartsenkring-hsl.be</a></li>
          <li>Verslaving: <a href="https://www.nvvp.net/website/patinten-informatie/aandoeningen-/verslaving">www.nvvp.net</a></li>
          <li>Valpreventiegids Stannah: <a href="https://www.stannah.be/nl-be/informatie/valpreventie-ouderen-ultieme-gids/">www.stannah.be</a></li>
        </ul>

        <mat-card-subtitle>
          Nuttige Contacten
        </mat-card-subtitle>
        <ul>
          <li>Levensbedreigende urgenties: T 112</li>
          <li>Huisartsenwachtpost (van vrijdagavond tot maandagochtend): T 1733</li>
          <li>Huisartsenwachtpost (van maandagochtend tot vrijdagochtend): T 09 280 08 80</li>
          <li>Apotheek van wacht: <a href="https://www.apotheek.be/nl/Pages/default.aspx">www.apotheek.be</a> (na 22u00 bellen via 0903 99 000)</li>
          <li>Tandarts van wacht: T 0903 39 969</li>
          <li>Antigifcentrum: T 070 245 245</li>
          <li>Zelfmoordpreventie: T 0800 325 123 of T 02 649 95 55</li>
          <li>Kind & gezin: <a href="https://www.kindengezin.be">www.kindengezin.be</a></li>
          <!-- <li>Huisartsenkring: <a href="https://www.huisartsenkring-hsl.be/urgentienummers/">www.huisartsenkring-hsl.be</a></li> -->
          <li>Teleonthaal T 106 of chat via <a href="https://www.tele-onthaal.be/">https://www.tele-onthaal.be/</a></li>
        </ul>

        <mat-card-subtitle>
          Sportgeneeskunde
        </mat-card-subtitle>
        <ul>
          <li>Sportmedische keuringen: <a href="https://www.sportkeuring.be/">www.sportkeuring.be</a></li>
          <li>Blessure preventie: <a href="https://www.veiligheid.nl/sportblessures?utm_campaign=301redirect&utm_medium=referral&utm_source=originalsource&utm_content=/voorkomblessures">www.veiligheid.nl</a></li>
          <li>Voetbal: <a href="https://www.rbfa.be/en">www.rbfa.be</a></li>
          <li><a href="https://www.uefa.com">www.uefa.com</a></li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
</div>
