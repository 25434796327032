<div>
  <mat-toolbar color="primary">
    <a mat-button class="companyName" [routerLink]="['']">
      <span><img src="../../assets/images/dokter67 logo.png" loading="lazy" class="logo" alt="Dokter67"></span>
    </a>
    <span class="example-spacer"></span>
    <div fxShow="true" fxHide.lt-md="true">
      <a mat-button [routerLink]="['']">Home</a>
      <a mat-button [routerLink]="['/praktijkinfo']">Praktijkinfo</a>
      <a mat-button [routerLink]="['/sportgeneeskunde']">Sportgeneeskunde</a>
      <a mat-button [routerLink]="['/links']">Interessante Links</a>
      <a mat-button [routerLink]="['/contact']">Contact</a>
    </div>

    <div fxShow="true" fxHide.gt-sm="true">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill class="example-container">
    <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over" opened="false" fxHide.gt-sm="true" position="end">
      <div fxLayout="column">
        <a mat-button [routerLink]="['']" (click)="sidenav.toggle()">Home</a>
        <a mat-button [routerLink]="['/praktijkinfo']" (click)="sidenav.toggle()">Praktijkinfo</a>
        <a mat-button [routerLink]="['/sportgeneeskunde']" (click)="sidenav.toggle()">Sportgeneeskunde</a>
        <a mat-button [routerLink]="['/links']" (click)="sidenav.toggle()">Interessante Links</a>
        <a mat-button [routerLink]="['/contact']" (click)="sidenav.toggle()">Contact</a>
      </div>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
      <ng-content></ng-content>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
