import { Component } from '@angular/core';
import { StoreService } from './services/app-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(public store: StoreService) {}
  title = 'dokter67';

  onOutletLoaded(component) {
    component.store = this.store;
  }
}
