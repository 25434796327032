<div class="container">
  <div class="row">

    <mat-card>
      <mat-card-title class="page-title">
        Covid-19
      </mat-card-title>
      <mat-card-content>
        <p>
          De CoViD-regels blijven van kracht!
        </p>
        <p>
          Wij verzoeken u bij symptomen die kunnen wijzen op Covid-19 om steeds een mond-neusmasker te dragen in de praktijk en om uw handen te ontsmetten bij het binnenkomen. U vindt een alcohol dispenser dadelijk bij het binnenkomen van de praktijk!
          Heeft u andere klachten is het mond-neusmasker niet meer verplicht, maar het blijft sterk aangeraden.
        </p>
        <p>Heeft u klachten die te linken zijn aan een CoViD-infectie of heeft u een risicocontact gehad, vermeld dit dan duidelijk als reden bij het maken van uw afspraak online. (U hoeft hiervoor niet meer telefonish te verwittigen.)</p>
        <p>Tussen twee patiënten door ontsmetten wij steeds ons materiaal.</p>
        <p>Wij verzoeken u ook om zoveel mogelijk contactloos te betalen. Dit kan u bij ons met behulp van Payconiq of met Bancontact.</p>
        <p>Bedankt voor uw medewerking!</p>

        <br>
        <p><strong>Raadpleeg eerst volgende pagina indien je een test wil, je resultaat wenst te raadplegen of andere vragen hebt.</strong></p>
        <button mat-raised-button class="mt-auto">
          <a href="https://mijncoronatest.be" class="first-button" alt="Mijn Corona Test">Mijn Corona Test</a>
        </button>
      </mat-card-content>
    </mat-card>

    <!-- <mat-card>
      <mat-card-title>
        PCR-test/antigeen sneltest afspraak
      </mat-card-title>
      <mat-card-content>
        <p>U kan steeds een afspraak maken via <a href="https://www.mtc-it4.be/patient/index.html#/appointments/day/bjNkcWkyQmFRMkpDVTRFNndleFgzRG8wSTNNVEVGMkxMdFZpeGQ2OStyYkhpeHdsYzJSRTBmazFFTzRZTzJMTw==">afspraken.be</a> op de vastgelegde testmomenten.</p>
        <p>Wenst u een afspraak te maken voor een PCR-test/antigeen sneltest, gelieve dit duidelijk te vermelden bij het maken van uw afspraak alsook het vermelden van de reden waarvoor u de test nodig heeft (nl. symptomen, reizen, ...).</p>
      </mat-card-content>
    </mat-card> -->

    <mat-card>
      <mat-card-title>
        Testbeleid
      </mat-card-title>
      <mat-card-content>
        <p>Vanaf 10 januari gelden nieuwe regels inzake test- en quarantainestrategie gezien de steile opmars van de 'omikronvariant'. Meer dan ooit komt de focus te liggen op <strong>vaccinatie</strong> en <strong>zelftests</strong>. Bekijk hieronder of je in aanmerking komt voor een test.</p>
        <p>U kan steeds een afspraak maken via <a href="https://www.mtc-it4.be/patient/index.html#/appointments/day/bjNkcWkyQmFRMkpDVTRFNndleFgzRG8wSTNNVEVGMkxMdFZpeGQ2OStyYkhpeHdsYzJSRTBmazFFTzRZTzJMTw==">afspraken.be</a> op de vastgelegde testmomenten.</p>
        <p>Wenst u een afspraak te maken voor een PCR/antigeen sneltest, gelieve dit duidelijk te vermelden bij het maken van uw afspraak, alsook het vermelden van de reden waarvor u de test nodig heeft (nl. symptomen, reizen,...).</p>
      </mat-card-content>
      <mat-card-content>
        Uw vaccinatiestatus bepaalt tot welke groep u behoort:
        <ul>
          <li><strong><u>Volledig gevaccineerd:</u></strong> u kreeg uw laatste prik (basisvaccinatie of boosterprik) of herstelcertificaat <u>ten laatste 5 maanden</u> geleden. Jongeren tussen 12 en 17 jaar die hun basisvaccinatie kregen ongeacht de datum vallen ook onder deze categorie.</li>
          <li><strong><u>Deels gevaccineerd:</u></strong> u kreeg uw laatste prik (basisvaccinatie of boosterprik) <u>langer dan 5 maanden</u> geleden.</li>
          <li><strong><u>Niet gevaccineerd:</u></strong> u werd nog niet gevaccineerd.</li>
          <li><strong><u>Kinderen tussen 5 en 11 jaar:</u></strong> zij worden beschouwd als ongevaccineerd tenzij zij ten laatste 5 maanden geleden besmet zijn geweest met COVID of een vaccin kregen.</li>
        </ul>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-title>
        Test bij symptomen
      </mat-card-title>
      <mat-card-content>
        <p>Wanneer u symptomen heeft die kunnen wijzen op COVID-19 gaat u onmiddelijk in quarantaine en vult u de <a href="https://sat.info-coronavirus.be/nl/formulier/sat">zelfevaluatietool</a> in. Als daaruit blijkt dat u getest moet worden, krijgt u een CTP-code voor een PCR-test. U maakt dan een afspraak online en vult deze CTP-code in bij de reden van consultatie.</p>
        <ul>
          <li>PCR-test is <strong>positief</strong>: u gaat 7 dagen in isolatie vanaf de eerste dag van symptomen, overigens mag u de laatste 3 dagen geen koorts meer hebben en moet u zich beter voelen.</li>
          <li>PCR-test is <strong>negatief</strong>: Quarantaine mag beëindigd worden.</li>
        </ul>
        <div class="col-lg-3">
          <a href="../../assets/images/flowchart symptomen.png">
            <img
              src="../../assets/images/flowchart symptomen.png"
              class="strategie-img"
              alt="Algoritme aangepaste teststrategie bij symptomen"
            />
          </a>
          <p style="float: right;"><strong>Bron: vrt.be</strong></p>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-title>
        Hoogrisico
      </mat-card-title>
      <mat-card-content>
        <p>Na een hoogrisicocontact wordt geen PCR-test meer afgenomen (tenzij u symptomen heeft, zie 'Test bij symptomen'). Ook moet u niet meer in quarantaine. Wel wordt een zelftest aangeraden.</p>
        <!-- <ul>
          <li><strong><u>Volledig gevaccineerd:</u></strong> U moet niet in quarantaine of getest worden (behalve bij symptomen). Blijf de komende dagen voorzichtig en besteed extra aandacht aan afstand houden en het dragen van maskers.</li>
          <li><strong><u>Deels gevaccineerd:</u></strong> U gaat 7 dagen in quarantaine, maar deze mag al op dag 4 beëindigd worden als u elke dag een negatieve zelftest aflegt van dag 4 t/m dag 7. Blijf de dagen erna voorzichtig en besteed extra aandacht aan afstand houden en het dragen van een maskers.</li>
          <li><strong><u>Niet gevaccineerd:</u></strong> U gaat 10 dagen in quarantaine, maar deze mag al op dag 7 beëindigd worden als u elke dag een negatieve zelftest aflegt van dag 7 t/m dag 10. Blijf de dagen erna voorzichtig en besteed extra aandacht aan afstand houden en het dragen van een maskers.</li>
        </ul> -->
        <!-- <div class="col-lg-3">
          <a href="../../assets/images/flowchart hoogrisico.png">
            <img
              src="../../assets/images/flowchart hoogrisico.png"
              class="strategie-img"
              alt="Algoritme aangepaste teststrategie bij hoogrisicocontact"
            />
          </a>
          <p style="float: right;"><strong>Bron: vrt.be</strong></p>
        </div> -->
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-title>
        Positieve zelftest
      </mat-card-title>
      <mat-card-content>
        <p>U gaat in isolatie en maakt online een afspraak voor een PCR-test.</p>
        <div class="col-lg-3">
          <a href="../../assets/images/flowchart positieve zelftest.png">
            <img
              src="../../assets/images/flowchart positieve zelftest.png"
              class="strategie-img"
              alt="Algoritme aangepaste teststrategie bij een positieve zelftest"
            />
          </a>
          <p style="float: right;"><strong>Bron: vrt.be</strong></p>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-title>
        Regels op school
      </mat-card-title>
      <mat-card-content>
        <p>Vraag altijd de regels na bij de directie indien u twijfelt. Bij een hoogrisicocontact is geen PCR-test meer nodig, tenzij u symptomen heeft.
          <!-- Hieronder vindt u alvast een aantal stelregels. -->
        </p>
        <!-- <ul>
          <li>Klassen moeten niet langer in quarantaine indien er besmettingen zijn vastgesteld.</li>
          <li>Leerlingen van de basisschool die een hoogrisicocontact binnen het gezin hebben opgelopen mogen naar school en moeten dus niet in quarantaine, tenzij zij symptomen vertonen.</li>
          <li>Leerlingen van de middelbare school moeten wel in quarantaine na een hoogrisicocontact binnen het gezin.</li>
        </ul> -->
        <!-- <div class="col-lg-3">
          <a href="../../assets/images/flowchart school.png">
            <img
              src="../../assets/images/flowchart school.png"
              class="strategie-img"
              alt="Algoritme aangepaste teststrategie op school"
            />
          </a>
          <p style="float: right;"><strong>Bron: vrt.be</strong></p>
        </div> -->
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-title>
        Testcentra en apothekers
      </mat-card-title>
      <mat-card-content>
        <p>U kan steeds terecht in een testcentrum naar keuze voor een PCR-test of bij bepaalde apothekers voor een antigeen sneltest. Hiervoor heeft u een CTP-code nodig om een afspraak te maken. U kunt deze verkrijgen door de <a href="https://www.info-coronavirus.be/nl/testen/#waar">zelfevaluatietool</a> in te vullen wanneer u symptomen heeft.</p>
        <p>De lijst van testcentra en apothekers vindt u hier:</p>
        <ul>
          <li><a href="https://testcovid.doclr.be/#!/saps">Interactieve kaart met testcentra</a></li>
          <li><a href="https://testcovid.be/">Lijst met testcentra van Gent en Antwerpen</a></li>
          <li><a href="https://www.apotheek.be/nl/apotheker/Pages/In-welke-apotheken-kan-ik-me-laten-testen-op-COVID.aspx">Lijst met apothekers waar je je kan laten testen</a></li>
        </ul>
        <p>Tip: Als de testcentra de eerstvolgende dagen geen vrije testmomenten meer hebben, kan u op weekdagen zonder afspraak terecht in het UZ Gent van 10-14u.</p>
      </mat-card-content>
    </mat-card>


    <mat-card>
      <mat-card-title>
        Richtlijnen bij reizen ten tijde van CoViD-19
      </mat-card-title>
      <mat-card-content>
        <p>- De richtlijnen verschillen per land en veranderen elke dag. Zoek goed op per land welke regels gelden bij het betreden van dat land. Meer informatie hierover vindt u via <a href="https://diplomatie.belgium.be/nl">diplomatie België</a></p>
        <p>- Download de <a href="https://covidsafe.be/nl">Covidsafe app</a> op uw gsm. Zo heeft u altijd uw vaccinatie en/of testcertificaten bij de hand.</p>
        <p>- Maak afspraken op vastgelegde testmomenten via <a href="https://www.mtc-it4.be/patient/index.html#/appointments/day/bjNkcWkyQmFRMkpDVTRFNndleFgzRG8wSTNNVEVGMkxMdFZpeGQ2OStyYkhpeHdsYzJSRTBmazFFTzRZTzJMTw==">afspraken.be</a>.</p>
        <p>- Een PCR-test om op reis te vertrekken is ten allen tijde betalend, ongeacht de vaccinatiestatus.</p>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-title>
        Symptomen
      </mat-card-title>
      <mat-card-content>
        <p>Voor up-to-date informatie omtrent CoViD-19 kan u altijd terecht op de volgende website: <a href="https://www.info-coronavirus.be/nl/">www.info-coronavirus.be</a></p>
        <mat-card-subtitle>
          De meest voorkomende symptomen:
        </mat-card-subtitle>
        <ul>
          <li>koorts</li>
          <li>droge hoest</li>
          <li>vermoeidheid</li>
        </ul>
        <mat-card-subtitle>
          Ernstige symptomen:
        </mat-card-subtitle>
        <ul>
          <li>kortademigheid of ademhalingsproblemen</li>
          <li>pijn of druk op de borst</li>
          <li>aangetast spraak- of bewegingsvermogen</li>
        </ul>
        <p>Zoek direct medische hulp als u ernstige symptomen heeft. Bel altijd eerst voordat u uw huisarts of een andere zorginstelling bezoekt.</p>
        <p>Mensen met lichte tot matige symptomen die verder gezond zijn, moeten thuis uitzieken.</p>
        <p>Het duurt gemiddeld vijf tot zes dagen tot een besmette persoon symptomen vertoont. In sommige gevallen verschijnen de symptomen pas na veertien dagen.</p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
