import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type Store = {
  modalOpen: boolean;
};

export const initialStoreState: Store = {
  modalOpen: true,
};

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private readonly _createStore = new BehaviorSubject<Store>(initialStoreState);
  readonly store$ = this._createStore.asObservable();

  private get store(): Store {
    return this._createStore.getValue();
  }

  private set store(val: Store) {
    this._createStore.next(val);
  }

  getValue(key: keyof Store): Store[keyof Store] {
    return this.store[key];
  }

  setValue(key: string, value: Store[keyof Store]) {
    this.store = {
      ...this.store,
      [key]: value,
    };
  }
}
