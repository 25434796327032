import { Component, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { StoreService } from 'src/app/services/app-store.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @Input() store: StoreService;

  isModalOpen: boolean = false;

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Welkom bij Dokter67. Algemene huisarts- en sportgeneeskunde. Stationstraat 67 - 9810 Eke-Nazareth.',
      },
      { name: 'author', content: 'Arthur Callant' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2021-09-29', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' },
      {
        name: 'keywords',
        content:
          'Dokter67, Sportarts, Eke, Nazareth, Dokter Kris Vanderlinden, Dokter Maxime Callant, Huisarts, Kris Vanderlinden, Maxime Callant, Algemene huisartsgeneeskunde, huisartsgeneeskunde, sportgeneeskunde',
      },
    ]);
    this.setTitle('Dokter67');
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  onModalClose(e: Event) {
    this.store.setValue('modalOpen', false);
    this.syncStoreToValues();
  }

  syncStoreToValues() {
    this.isModalOpen = this.store.getValue('modalOpen');
  }

  ngOnInit(): void {
    this.syncStoreToValues();
  }
}
