import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CovidComponent } from './components/covid/covid.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ContactComponent } from './components/contact/contact.component';
import { SportgeneeskundeComponent } from './pages/sportgeneeskunde/sportgeneeskunde.component';
import { LinksPageComponent } from './pages/links-page/links-page.component';
import { PraktijkinfoComponent } from './pages/praktijkinfo/praktijkinfo.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'covid', component: CovidComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'praktijkinfo', component: PraktijkinfoComponent },
  { path: 'sportgeneeskunde', component: SportgeneeskundeComponent},
  { path: 'links', component: LinksPageComponent },
  { path: '', redirectTo: 'dokter67', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
