<div class="container-fluid">
  <mat-card class="row align-content-center">
    <div class="col">
      <mat-card-content>
        De COVID-regels blijven van kracht!
      </mat-card-content>
      <mat-card-content>
        U draagt steeds een mond-neusmasker in de praktijk.
      </mat-card-content>
      <mat-card-content>
        Heeft u klachten die te linken zijn aan een COVID-infectie of heeft u een risicocontact gehad, vermeld dit dan duidelijk als reden bij het maken van uw afspraak online. (U hoeft hiervoor niet meer telefonisch te verwittigen.)
      </mat-card-content>
      <mat-card-content>
        Voor meer informatie over COVID-testen klikt u <a [routerLink]="['../dokter67/covid']">hier</a>.
      </mat-card-content>
      <mat-card-content>
        Bedankt voor uw medewerking!
      </mat-card-content>
    </div>
  </mat-card>
</div>
