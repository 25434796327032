import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sportgeneeskunde',
  templateUrl: './sportgeneeskunde.component.html',
  styleUrls: ['./sportgeneeskunde.component.css']
})
export class SportgeneeskundeComponent implements OnInit {
  images = [11, 12, 13].map((n) => `../assets/images/${n}.jpg`);

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'Dokter67 - Sportgeneeskunde'},
      {name: 'author', content: 'Arthur Callant'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'date', content: '2021-09-29', scheme:'YYYY-MM-DD'},
      {charset: 'UTF-8'},
      {name: 'keywords', content: 'Dokter67, Sportarts, Eke, Nazareth, Dokter Kris Vanderlinden, Dokter Maxime Callant, Huisarts, Kris Vanderlinden, Maxime Callant, Inspanningsproef met lactaatmeting, inspanningsproef, lactaatmeting, sportgeneeskunde, echografie, sportmedische keuringen, sportarts, toelatingsattest'}
    ]);
    this.setTitle('Sportgeneeskunde - Dokter67')
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {
  }

}
