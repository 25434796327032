<div class="container-fluid">
  <div class="row">
    <mat-card>
      <img
        mat-card-image
        src="../../assets/images/parallax_background.jpg"
        id="banner-image"
        appParallax
        [ratio]="0.2"
        alt="Dokterspraktijk Dokter67"
      />
    </mat-card>
    <div
      class="container text-center d-flex justify-content-center align-items-center"
    >
      <mat-card
        class="row card-1 d-flex justify-content-center text-center align-items-center"
      >
        <mat-card-title
          style="color: black"
          class="text-center d-flex align-items-center flex-column col bottom-align-text card-title-1"
        >
          Maak uw afspraak online.
        </mat-card-title>
        <mat-card-content
          class="row text-center d-flex justify-content-center align-items-center flex-column bottom-align-text card-content-1"
        >
          <button mat-raised-button class="mt-auto">
            <a
              href="https://www.mtc-it4.be/patient/index.html#/appointments/day/bjNkcWkyQmFRMkpDVTRFNndleFgzRG8wSTNNVEVGMkxMdFZpeGQ2OStyYkhpeHdsYzJSRTBmazFFTzRZTzJMTw=="
            >
              Maak afspraak
            </a>
          </button>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div class="spacer"></div>
<!-- <app-covid-banner></app-covid-banner> -->
<div class="container-fluid">
  <div class="row">
    <mat-card class="col-lg card-2">
      <mat-card-title
        class="text-center d-flex align-items-center flex-column col card-title-2"
      >
        Afspraken
      </mat-card-title>
      <mat-card-content
        class="text-center d-flex align-items-center flex-column col bottom-align-text card-content-2"
      >
        Een afspraak maak je online via
        <a
          href="https://www.mtc-it4.be/patient/index.html#/appointments/day/bjNkcWkyQmFRMkpDVTRFNndleFgzRG8wSTNNVEVGMkxMdFZpeGQ2OStyYkhpeHdsYzJSRTBmazFFTzRZTzJMTw=="
          >afspraken.be</a
        >
        Gelieve rekening te houden met specifieke vastgelegde
        afspraakmogelijkheden (bijvoorbeeld 'bloedonderzoek').
        <p>Gelieve per persoon 1 afspraak te boeken.</p>
      </mat-card-content>
    </mat-card>
    <mat-card class="col-lg card-2">
      <mat-card-title
        class="text-center d-flex align-items-center flex-column col card-title-2"
      >
        Bloedresultaten
      </mat-card-title>
      <mat-card-content
        class="text-center d-flex align-items-center flex-column col bottom-align-text card-content-2"
      >
        <p style="margin-bottom: 0 !important">
          Voor uitslagen zoals die van een bloedonderzoek stuurt u een
          <span>e-mail</span> naar de praktijk via
        </p>
        <a href="mailto:info@dokter67.be">info@dokter67.be</a>
        <p>
          Hierna zal de arts u telefonisch of via e-mail op de hoogte brengen
          van de resultaten.
        </p>
        <p>U hoeft dus zelf niet te bellen.</p>
      </mat-card-content>
    </mat-card>
    <mat-card class="col-lg card-2">
      <mat-card-title
        class="text-center d-flex align-items-center flex-column col card-title-2"
      >
        Telefoon
      </mat-card-title>
      <mat-card-content
        class="text-center d-flex align-items-center flex-column col bottom-align-text card-content-2"
      >
        <p>Wij zijn telefonisch bereikbaar voor dringende zaken.</p>
        <p style="margin-bottom: 0 !important">
          Voor minder dringende vragen kan je een mail sturen naar
        </p>
        <a href="mailto:info@dokter67.be">info@dokter67.be</a>
        <p>
          Op die manier wordt de telefoon vrij gehouden voor dringende oproepen.
        </p>
      </mat-card-content>
    </mat-card>
    <mat-card class="col-lg card-2">
      <mat-card-title
        class="text-center d-flex align-items-center flex-column col card-title-2"
      >
        Voorschriften en attesten
      </mat-card-title>
      <mat-card-content
        class="text-center d-flex align-items-center flex-column col bottom-align-text card-content-2"
      >
        <p>
          Voor voorschriften en attesten wordt steeds een consultatie gepland.
          Deze worden dus niet meer zonder contact via e-mail verstuurd.
        </p>
        <p>
          Tijdens de consultatie wordt nagegaan of de dosis nog correct is, of
          er bijwerkingen optreden, of de medicatie moet aangepast worden en of
          deze nog wel nodig is.
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="spacer-2"></div>

<app-news></app-news>

<div class="spacer-2"></div>

<div class="container-fluid">
  <div class="row d-flex justify-content-center row-1">
    <mat-card class="text-center card-praktijk">
      <hr />
      <mat-card-title class="text-center praktijk-title">
        Ons Team
      </mat-card-title>
      <mat-card-subtitle class="text-center praktijk-subtitle">
        Sportarts - Huisarts Kris Vanderlinden
      </mat-card-subtitle>
      <mat-card-content class="row align-items-center row-2">
        <div class="col-lg-5">
          <img
            src="../../assets/images/dokter kris vanderlinden.jpg"
            class="dokter-img"
            loading="lazy"
            alt="Dokter Kris Vanderlinden"
          />
        </div>
        <div class="col-lg-6 align-items-left">
          <p>
            Dr. Kris Vanderlinden is afgestudeerd als Huisarts aan de
            Universiteit Gent in 1992 en behaalde in 1993 met grote
            onderscheiding het aanvullende getuigschrift in de Sportgeneeskunde.
            Hiernaast voltooide hij ook de opleiding voor het maken van
            röntgenfoto's. Hij volgt tevens een opleiding in Musculoskeletale
            Echografie.
          </p>
          <p>
            Heden is Dr. Kris parttime bondsarts bij de nationale voetbalbond
            KBVB voor de A-ploeg vrouwen, The Red Flames.
          </p>
          <p>
            Naast stagemeester is Dr. Kris ook erkend als praktijkopleider, wat
            betekent dat jong afgestudeerde huisartsen (haio = huisarts in
            opleiding) in zijn praktijk opleiding kunnen volgen. Dr. Kris volgt
            daarvoor zelf een specifieke bijscholing.
          </p>
        </div>
      </mat-card-content>
      <hr />

      <mat-card-subtitle class="text-center praktijk-subtitle">
        Sportarts - Huisarts Maxime Callant
      </mat-card-subtitle>
      <mat-card-content class="row align-items-center row-2">
        <div class="col-lg-6 align-items-right">
          <p>
            Dokter Maxime Callant studeerde af als arts in 2018 aan de UGent en
            behaalde het aanvullende getuigschrift in de sportgeneeskunde. In
            2019 was hij aan de slag als sportarts bij Mensana in Sleidinge en
            als consultatiearts bij Kind & Gezin.
          </p>
          <p>
            Sinds 2021 is hij verbonden aan de praktijk als sportarts/huisarts.
          </p>
        </div>
        <div class="col-lg-5">
          <img
            src="../../assets/images/dokter maxime callant.jpg"
            class="dokter-img"
            loading="lazy"
            alt="Dokter Maxime Callant"
          />
        </div>
      </mat-card-content>
      <hr />

      <mat-card-subtitle class="text-center praktijk-subtitle">
        HAIO Pauline Van Der Meeren
      </mat-card-subtitle>
      <mat-card-content class="row align-items-center row-2">
        <div class="col-lg-5">
          <img
            src="../../assets/images/dokter pauline van der meeren.JPG"
            class="dokter-img"
            loading="lazy"
            alt="Dokter Janne Dewaele"
          />
        </div>
        <div class="col-lg-6 align-items-left">
          <p>
            Dokter Pauline Van Der Meeren haalde haar diploma als arts aan de
            UGent en is begonnen met haar eerste jaar van de huisartsenopleiding
            in deze praktijk.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="spacer-2"></div>

<!-- <div class="container-fluid">
  <div class="row d-flex justify-content-center row-3">
    <mat-card class="text-center card-diensten">
      <hr>
      <mat-card-title class="text-center praktijk-title">
        Diensten
      </mat-card-title>
      <mat-card-content class="align-items-center row-2">

      </mat-card-content>
      <hr>
    </mat-card>
  </div>
</div> -->

<!-- <app-modal [open]="isModalOpen" (action)="onModalClose($event)">
  <h1>COVID Vaccinatie</h1>
  <p>
    Indien u tot een risicogroep behoort en gevaccineerd wenst te worden tegen
    het coronavirus kan u een een afspraak maken via
    <a
      href="https://www.mtc-it4.be/patient/index.html#/appointments/day/bjNkcWkyQmFRMkpDVTRFNndleFgzRG8wSTNNVEVGMkxMdFZpeGQ2OStyYkhpeHdsYzJSRTBmazFFTzRZTzJMTw=="
      >afspraken.be</a
    >
    op een van de voorziene vaccinatiedagen.
  </p>
</app-modal> -->
