<div class="container-fluid">
  <div class="full" id="map">
  </div>
</div>
<div class="container-fluid back-black">
    <div class="rowspacer back-black"></div>
    <div class="row back-black">
      <div class="col-lg-2 colspacer back-black"></div>
      <div class="col-lg-2 text-center-small border-top back-black">
        <h5>Adres</h5>
        <p>Stationsstraat 67</p>
        <p>9810 Eke-Nazareth</p>
      </div>
      <div class="col-lg-4 border text-center-small">
        <h5>Openingstijden</h5>
        <p>
          De praktijk is elke weekdag open, steeds op afspraak. Er zijn geen vrije consultaties voorzien.
          Tijdens het weekend kan u zich melden op de huisartsenwachtpost via het gratis nummer 1733.
        </p>
        <!-- <table>
          <tr>
            <th>Dag</th>
            <th>Voormiddag</th>
            <th>Namiddag</th>
          </tr>
          <tr>
            <td>Ma</td>
            <td>Dr. Vanderlinden en Dr. Callant</td>
            <td>Dr. Vanderlinden en Dr. Callant</td>
          </tr>
          <tr>
            <td>Di</td>
            <td>Dr. Vanderlinden</td>
            <td>Dr. Vanderlinden</td>
          </tr>
          <tr>
            <td>Wo</td>
            <td>Dr. Vanderlinden en Dr. Callant</td>
            <td>Dr. Vanderlinden en Dr. Callant</td>
          </tr>
          <tr>
            <td>Do</td>
            <td>Dr. Vanderlinden en Dr. Callant</td>
            <td>Dr. Callant</td>
          </tr>
          <tr>
            <td>Vr</td>
            <td>Dr. Vanderlinden en Dr. Callant</td>
            <td>Dr. Vanderlinden en Dr. Callant</td>
          </tr>
        </table> -->
      </div>
      <div class="col-lg-3 text-center-small border-bottom">
        <h5>Contactinformatie</h5>
        <p>09/385.43.46</p>
        <p>Gelieve niet te bellen tussen 12.30u en 14.30u hetzij voor dringende zaken</p>
        <a href="mailto:info@dokter67.be">info@dokter67.be</a>
      </div>
      <div class="col-lg-2 colspacer back-black"></div>
    </div>
    <div class="rowspacer"></div>
</div>

<div class="container-fluid">
  <div class="row d-flex justify-content-center row-white">
    <mat-card class="text-center card-afspraak card-white">
      <hr>
      <mat-card-title class="text-center title-black">
        Afspraken
      </mat-card-title>
      <mat-card-content class="row align-items-center row-2">
        <div class="col-lg-8 text-align-left">
          <p>
            Afspraken kunnen worden gemaakt via <a href="https://www.mtc-it4.be/patient/index.html#/appointments/day/bjNkcWkyQmFRMkpDVTRFNndleFgzRG8wSTNNVEVGMkxMdFZpeGQ2OStyYkhpeHdsYzJSRTBmazFFTzRZTzJMTw==">www.afspraken.be</a>.
          </p>
          <p>- Log in met uw account of registreer indien het de eerste keer is dat u online een afspraak maakt.</p>
          <p>- Na toelating op de site kan u een arts selecteren</p>
          <p>- Nadien kan u uw afspraak reserveren</p>
          <p>- Gelieve bij de reservatie uw telefoonnummer en reden van afspraak toe te voegen</p>
          <p><span style="font-weight: 800;">- Gelieve 5 minuten voor uw afspraak aanwezig te zijn!</span></p>
          <p><span style="font-weight: 800;">- Gelieve 2 afspraken te maken als u met 2 personen komt!</span></p>
        </div>
      </mat-card-content>
      <hr>
    </mat-card>
  </div>
</div>

<div class="container-fluid">
  <div class="row d-flex justify-content-center row-black">
    <mat-card class="text-center card-afspraak card-black">
      <hr>
      <mat-card-title class="text-center title-yellow">
        Huisbezoeken
      </mat-card-title>
      <mat-card-content class="row align-items-center row-2">
        <div class="col-lg-6 text-align-left">
          <p>
            <span style="font-weight: 800;">Huisbezoeken kan u telefonisch aanvragen op 09/385.43.46</span>
          </p>
          <p><span style="font-weight: 800; color: rgb(248, 83, 83);">Gelieve huisbezoeken voor die dag aan te vragen vóór 11.00u.</span></p>
          <p>Het is mogelijk dat u na enkele seconden wordt doorgeschakeld naar het mobiele nummer van de dokter. Gelieve dus niet in te haken als u doorschakeling hoort maar eventjes te wachten tot u de dokter aan de lijn krijgt (deze doorschakeling is gratis).</p>
        </div>
      </mat-card-content>
      <hr>
    </mat-card>
  </div>
</div>


<div class="container-fluid">
  <div class="row d-flex justify-content-center row-white">
    <mat-card class="text-center card-afspraak card-white">
      <hr>
      <mat-card-title class="text-center title-black">
        Bereikbaarheid
      </mat-card-title>
      <mat-card-content class="row align-items-center row-2">
        <div class="col-lg-7 text-align-left">
          <mat-card-subtitle>
            Auto:
          </mat-card-subtitle>
          <p>N60 Gent-Oudenaarde -verkeerslichten Groene Vallei te Eke- Stationsstraat halverwege tussen de kerk en het station.</p>
          <p>Er is parkeergelegenheid aan de praktijk.</p>

          <mat-card-subtitle>
            Openbaar vervoer:
          </mat-card-subtitle>
          <p>Er is een NMBS halte op de lijn Gent-Oudenaarde-Ronse namelijk Eke-Nazareth op &lt; 500 meter van de praktijk.</p>

          <mat-card-subtitle>
            Bus:
          </mat-card-subtitle>
          <p>Vanuit Gent Lijn 71<br>Vanuit Deinze Lijn 81 of 84</p>
          <p>De bushalte ligt op &lt; 200 meter van de praktijk (tussen NMBS station en dorp Eke).</p>
        </div>
      </mat-card-content>
      <hr>
    </mat-card>
  </div>
</div>
