<div class="container-fluid">
  <div class="row">
    <mat-card>
      <mat-card-title class="text-center page-title"> Nieuws </mat-card-title>
    </mat-card>

    <!-- class="col-lg-2" -->
    <div></div>

    <mat-card class="col-lg-4"></mat-card>
    <!-- class="col-lg-4 border" -->
    <mat-card class="col-lg-4">
      <mat-card-subtitle class="text-center subtitle">
        Nieuwe collega
      </mat-card-subtitle>
      <mat-card-content class="row text-center">
        <mat-card-content>
          Dokter Janne Dewaele heeft haar eerste jaar van de HAIO opleiding
          voltooid en zal deze in een andere praktijk verder zetten. Pauline Van
          Der Meeren zal haar plaats innemen vanaf oktober.
        </mat-card-content>
      </mat-card-content>
    </mat-card>

    <!-- <mat-card class="col-lg-4">
      <mat-card-subtitle class="text-center subtitle">
        Nieuwe collega
      </mat-card-subtitle>
      <mat-card-content class="row text-center">
        <mat-card-content>
          Op 1 oktober 2022 is dokter Janne Dewaele gestart als nieuwe HAIO in de praktijk.
        </mat-card-content>
        <mat-card-content>
          Dokter Callant blijft verbonden aan de praktijk.
        </mat-card-content>
      </mat-card-content>
    </mat-card> -->

    <!-- <mat-card class="col-lg-4">
      <mat-card-subtitle class="text-center subtitle">
        <strong>Opgelet!</strong> Nieuw testbeleid 10 januari 2022
      </mat-card-subtitle>
      <mat-card-content class="row text-center">
        <mat-card-content>
          Alvorens een afspraak te maken, lees eerst onze <a [routerLink]="['/covid']">Covid 19</a> pagina waar u alle informatie terugvindt over de nieuwe regels rond testen en quarantaine.
        </mat-card-content>
      </mat-card-content>
    </mat-card> -->
    <div class="col-lg-4"></div>
  </div>
</div>
