<footer class="page-footer font-small blue pt-4">
  <div class="container-fluid text-center text-md-left">
    <div class="row">
      <div class="col">
        <h5>
          <div class="row mt-3">
            <!-- <div class="spacer col-1"></div> -->
            <div
              class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4 align-content-center"
            >
              <h5>
                <span style="font-weight: 800">Dokter67</span>
              </h5>
              <p>Stationsstraat 67</p>
              <p>9810 Eke-Nazareth</p>
              <p>
                <a href="https://www.instagram.com/dokterspraktijk67/"
                  ><i class="fab fa-instagram"></i> Instagram</a
                >
              </p>
            </div>

            <div
              class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 align-content-center"
            >
              <h5>
                <span style="font-weight: 800">Urgente hulp</span>
              </h5>
              <p class="height-24"><mat-icon>phone</mat-icon> 112</p>
              <p class="height-24">
                <mat-icon>phone</mat-icon> 1733 (Huisartsenwachtpost)
              </p>
            </div>
            <div
              class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4 align-content-center"
            >
              <h5>
                <span style="font-weight: 800">Maak een afspraak</span>
              </h5>
              <p>
                Een afspraak kan je maken via
                <a
                  href="https://www.mtc-it4.be/patient/index.html#/appointments/day/bjNkcWkyQmFRMkpDVTRFNndleFgzRG8wSTNNVEVGMkxMdFZpeGQ2OStyYkhpeHdsYzJSRTBmazFFTzRZTzJMTw=="
                  >afspraken.be</a
                >
              </p>
            </div>
          </div>
        </h5>
      </div>
    </div>
  </div>
  <div class="footer-copyright text-center">
    <span style="font-size: 13px"
      >Website design by
      <a href="https://arthurcallant.github.io/portfolio-react/"
        >Arthur Callant</a
      ></span
    >
  </div>
</footer>
