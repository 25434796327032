<div class="container">
  <div class="spacer"></div>
  <ngb-carousel *ngIf="images" [interval]="4000" [showNavigationIndicators]="showNavigationIndicators">
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img [src]="images[0]" alt="Dokterspraktijk Dokter67">
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img [src]="images[1]" alt="Dokter Kris Vanderlinden en Dokter Maxime Callant">
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img [src]="images[2]" alt="Doktersruimte Dokter67">
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img [src]="images[3]" alt="Wachtzaal Dokter67">
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img [src]="images[4]" alt="Echografie bij Dokter67">
      </div>
    </ng-template>
  </ngb-carousel>
  <div class="row">
    <mat-card class="col-lg-6">
      <mat-card-title>
        Algemene huisartsgeneeskunde
      </mat-card-title>
      <mat-card-content>
        <ul>
          <li>Algemene ziekte- en gezondheidsproblemen</li>
          <li>Acute zorgen en EHBO</li>
          <li>Opvolging van chronische ziekte</li>
          <li>Controleren en aanpassen bloeddruk- of hartmedicatie</li>
          <li>Preoperatief- en verzekeringsonderzoek</li>
          <li>Vaccinatie en preventie</li>
        </ul>
      </mat-card-content>
    </mat-card>

    <mat-card class="col-lg-6">
      <mat-card-title>
        Sportgeneeskunde
      </mat-card-title>
      <mat-card-content>
        <ul>
          <li>Blessurebehandeling en -preventie</li>
          <li>Inspanningstest (fietsproef) met lactaatmeting</li>
          <li>Sportmedische keuring</li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="row">
    <mat-card class="col-lg-6">
      <mat-card-title>
        Technische handelingen
      </mat-card-title>
      <mat-card-content>
        <ul>
          <li>Bloedafname</li>
          <li>Urine- en stoelgangstalen</li>
          <li>Elektrocardiogram (ECG)</li>
          <li>Röntgenopnamen (RX) en echografie (opm: enkel voor bewegingsstelsel)</li>
          <li>Oorprop verwijderen</li>
          <li>Cardiovasculaire fietsproef</li>
          <li>Kleine chirurgische ingrepen - Wondhechting/verwijderen van hechtingen - Wondzorg</li>
          <li>Cryotherapie en behandeling van wratten</li>
          <li>Gipsen: zowel aanleggen als afhalen</li>
        </ul>
      </mat-card-content>
    </mat-card>

    <mat-card class="col-lg-6">
      <mat-card-title>
        Injecties
      </mat-card-title>
      <mat-card-content>
        <ul>
          <li>Biopunctuur/Mesotherapie</li>
          <li>Intra-articulaire injecties</li>
          <li>Cortisoneïnfiltratie</li>
          <li>Hyalonzuurinfiltratie</li>
          <li>Platelet Rich Plasma (PRP)</li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="row">
    <mat-card>
      <mat-card-content>
        <p style="font-size: 16px; font-weight: bold;">Breng bij raadplegingen steeds uw identiteitskaart (eID) mee. Dit moet minstens jaarlijks ingelezen worden om het medisch dossier (administratie, GMD, SUMEHR, ...) up to date te houden.</p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
