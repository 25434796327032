import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-praktijkinfo',
  templateUrl: './praktijkinfo.component.html',
  styleUrls: ['./praktijkinfo.component.css']
})
export class PraktijkinfoComponent implements OnInit {
  images = [111, 114, 112, 113, 115].map((n) => `../assets/images/${n}.jpg`);
  showNavigationIndicators = true;

  constructor(config: NgbCarouselConfig, private meta: Meta, private title: Title) {
    config.showNavigationIndicators = true;
    this.meta.addTags([
      { name: 'description', content: 'Dokter67 - Praktijkinfo' },
      { name: 'author', content: 'Arthur Callant' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1'},
      { name: 'date', content: '2021-09-29', scheme:'YYYY-MM-DD'},
      { charset: 'UTF-8'},
      { name: 'keywords', content: 'Dokter67, Sportarts, Eke, Nazareth, Dokter Kris Vanderlinden, Dokter Maxime Callant, Huisarts, Kris Vanderlinden, Maxime Callant, Praktijkinfo, Algemene huisartsgeneeskunde, huisartsgeneeskunde, sportgeneeskunde, technische handelingen, injecties' }
    ]);
    this.setTitle('Praktijkinfo - Dokter67');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {
  }

}
