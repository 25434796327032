import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-links-page',
  templateUrl: './links-page.component.html',
  styleUrls: ['./links-page.component.css']
})
export class LinksPageComponent implements OnInit {

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'Dokter67 - Interessante links'},
      {name: 'author', content:'Arthur Callant'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'date', content: '2021-09-29', scheme:'YYYY-MM-DD'},
      {charset: 'UTF-8'},
      {name: 'keywords', content: 'Dokter67, Sportarts, Eke, Nazareth, Dokter Kris Vanderlinden, Dokter Maxime Callant, Huisarts, Kris Vanderlinden, Maxime Callant, interessante links, contactpersoon'}
    ]);
    this.setTitle('Interessante links - Dokter67');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {
  }

}
